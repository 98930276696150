<template>
  <div>
    <div v-if="id !== -1">
      <div v-if="formAction" style="padding: 20px">
        <div class="titles">{{ __("Label") }}</div>
        <div class="details">{{ contentFormInfo.label }}</div>

        <div class="titles">{{ __("URL") }}</div>
        <div class="details">{{ contentFormInfo.url }}</div>

        <div class="titles">{{ __("Live") }}</div>
        <div class="details">
          {{ isArchived(contentFormInfo.is_archived) }}
        </div>

        <el-button
          @click="handleEditBtnClick"
          class="editBtn"
          :disabled="!canWrite()"
          >{{ __("Edit") }}
        </el-button>
        <el-button
          @click="handleDelete(contentFormInfo)"
          style="margin-top: 10px"
          class="deleteBtn"
          :disabled="!canWrite()"
          >{{ __("Delete") }}
        </el-button>
      </div>
    </div>

    <el-dialog
      v-if="openModal"
      :visible.sync="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isLoading"
    >
      <el-scrollbar :native="false">
        <div style="max-height: 100vh;">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <page-header :title="__('What\'s New')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="label" :label="__('Whats New')">
                  <el-input
                    :placeholder="__('Enter name')"
                    v-model="contentForm.label"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="url" :label="__('URL')">
                  <el-input
                    :placeholder="__('Enter url')"
                    v-model="contentForm.url"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="id !== -1" type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item prop="is_archived" :label="__('Live')">
                  <div slot="label">
                    <span v-if="!atMaxLiveContents">{{ __("Live") }}</span>
                    <span v-else
                      >{{ __("Live") }}
                      <el-tooltip
                        effect="dark"
                        :content="
                          __('Already at maximum live contents at dashboard')
                        "
                        placement="right-start"
                      >
                        <i class="el-icon-info"></i> </el-tooltip
                    ></span>
                  </div>
                  <el-checkbox :disabled="atMaxLiveContents" v-model="isLive">{{
                    __("Show in dashboard")
                  }}</el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div
            slot="footer"
            style="display: flex; margin-top: 35px; margin-left: 25%;margin-bottom: 20px"
          >
            <save-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :primaryKey="id"
              variant="CreateUpdate"
            />
            <el-button @click="handleCancelBtn" class="cancelBtn"
              >{{ __("Cancel") }}
            </el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import BaseContent from "@/views/build/content/mixins/BaseContent";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { urlFieldValidation } from "@/utils/formValidationRules";

export default {
  components: { PageHeader, SaveButton },
  mixins: [BaseContent, BaseContentInfoPanel],
  data() {
    const validateURL = (rule, value, callback) => {
      let isValid = urlFieldValidation(rule, value);
      isValid ? callback() : callback(__("enter valid URL"));
    };
    return {
      rules: {
        label: [
          {
            required: true,
            message: __("Label cannot be empty"),
            trigger: "blur"
          }
        ],
        url: [
          {
            required: true,
            message: __("URL cannot be empty"),
            trigger: "blur"
          },
          { validator: validateURL, trigger: "blur" }
        ]
      },
      isSubmitting: false
    };
  },
  computed: {
    isArchived() {
      return is_archived => {
        return is_archived ? __("No") : __("Yes");
      };
    },
    ...mapState("announcements", {
      isLoading: state => state.loading,
      liveWhatsNewCount: state => state.countLiveWhatsNew
    }),
    atMaxLiveContents() {
      return (
        !this.isLive &&
        !!this.contentFormInfo.is_archived &&
        this.liveWhatsNewCount >= 3
      );
    },
    isLive: {
      get() {
        return !this.contentForm.is_archived;
      },
      set(val) {
        this.contentForm.is_archived = !val;
      }
    }
  },
  methods: {
    ...mapActions("announcements", {
      deleteContentMethod: "deleteAnnouncement",
      undoDeleteContent: "undoDeleteAnnouncement",
      createAnnouncement: "createAnnouncement",
      updateAnnouncement: "updateAnnouncement"
    }),

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleCancelBtn() {
      this.resetForm("contentForm");
      this.handleCancel();
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createAnnouncement : this.updateAnnouncement;
          this.isSubmitting = true;
          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("dashboard content created successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("dashboard content updated successfully")
                  });

              EventBus.$emit("list-changed", data.data);
              this.handleCancelBtn();
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

.page-header {
  padding-left: 0;
}
</style>
