<script>
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import List from "@/views/system/announcements/whats-new/pages/list";
import CreateOrEdit from "@/views/system/announcements/whats-new/pages/createOrEdit";
import { mapState } from "vuex";
import { ANNOUNCEMENT_TYPE } from "@/constants/announcementTypes";
import _ from "lodash";

export default {
  name: "AnnouncementsWhatsNewIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },
  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("announcements", {
      contents: state => state.whats_new
    })
  },

  methods: {
    initContent() {
      return {
        announcement_type: ANNOUNCEMENT_TYPE.WHATS_NEW,
        label: "",
        url: "",
        is_archived: 1,
        is_enabled: 1
      };
    }
  },
  mounted() {
    this.contentType = _.startCase(
      _.split(ANNOUNCEMENT_TYPE.WHATS_NEW, "_").join(" ")
    );
    this.primaryKey = "announcement_id";
    // this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
