<template
  ><div style="width: 100%;" @click="handleClear">
    <el-button
      class="createBtn"
      @click.stop="createContentItem"
      :disabled="!canWrite()"
      >{{ __("Create Whats New") }}</el-button
    >

    <div style="padding: 0 36px;margin-top: 165px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          :show-slot="true"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>

      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="whatsNewAnnouncementsTable"
            v-loading="loading"
            :data="announcements"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            @current-change="handleCurrentChange"
            highlight-current-row
            class="list-table"
            v-show="canRead()"
          >
            <el-table-column :label="__('Label')">
              <template slot-scope="scope">
                <span>{{ scope.row.label }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('URL')">
              <template slot-scope="scope">
                <span>{{ scope.row.url }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('Live')">
              <template slot-scope="scope">
                <div
                  v-if="!scope.row.is_archived"
                  style="display: flex; align-items: center"
                >
                  <div :style="getStatusStyles"></div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "AnnouncementsWhatsNewList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar
  },
  data() {
    return {
      search: "",
      currentRow: ""
    };
  },
  computed: {
    ...mapState("announcements", {
      announcements: state => state.whats_new,
      loading: state => state.loading
    }),
    getStatusStyles() {
      return {
        width: "16px",
        height: "16px",
        "border-radius": "8px",
        "margin-right": "10px",
        "background-color": "var(--theme-color)"
      };
    }
  },
  methods: {
    ...mapActions("announcements", {
      contentAPI: "getAnnouncements",
      deleteContentMethod: "deleteAnnouncement"
      // undoDeleteContent: "undoDeleteAnnouncement"
    }),
    handleClear() {
      this.$refs["whatsNewAnnouncementsTable"].setCurrentRow();
      this.handleClearSelection();
    },
    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      this.handleEdit(null, row);
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== undefined && val !== null && val !== -1) {
          this.$refs["whatsNewAnnouncementsTable"] &&
            this.$refs["whatsNewAnnouncementsTable"].setCurrentRow(
              this.announcements[val]
            );
        } else if (val === -1) {
          this.$refs["whatsNewAnnouncementsTable"] &&
            this.$refs["whatsNewAnnouncementsTable"].setCurrentRow();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
